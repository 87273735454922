:root {
    --black: #000000;
    --white: #ffffff;
    --transparent: rgba(0, 0, 0, 0);
    --fullBlack: rgba(0, 0, 0, 1);
    --darkBlack: rgba(0, 0, 0, 0.87);
    --lightBlack: rgba(0, 0, 0, 0.54);
    --minBlack: rgba(0, 0, 0, 0.26);
    --faintBlack: rgba(0, 0, 0, 0.12);
    --fullWhite: rgba(255, 255, 255, 1);
    --darkWhite: rgba(255, 255, 255, 0.87);
    --lightWhite: rgba(255, 255, 255, 0.54);
    --minWhite: rgba(255, 255, 255, 0.26);
    --faintWhite: rgba(255, 255, 255, 0.12);
    --grey300: #e0e0e0;
    --grey900: #212121;
}