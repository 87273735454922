.react-page-cell-droppable {
  /*position: relative;*/
}

.react-page-cell-droppable-is-over-current:after {
  /* extend dropable area a bit, so that its easier to add as last eleent */
  content: '';
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: -100px;
}

.react-page-cell-droppable-is-over-current:before {
  content: ' ';
  display: block;
  position: absolute;
  z-index: 1;
  background: var(--darkBlack);
}

.react-page-cell-droppable-is-over-above:before,
.react-page-cell-droppable-is-over-below:before {
  border-left: 0;
  border-right: 0;
}
.react-page-cell-droppable-is-over-left-of:before,
.react-page-cell-droppable-is-over-right-of:before {
  border-top: 0;
  border-bottom: 0;
}

.react-page-cell-droppable-is-over-above:before {
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  border-left: 0;
  border-right: 0;
}
.react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-above:before {
  height: 4px;
}

.react-page-cell-droppable-is-over-below:before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
}
.react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-below:before {
  height: 4px;
}

.react-page-cell-droppable-is-over-left-of:before {
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
}
.react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-left-of:before {
  width: 4px;
}

.react-page-cell-droppable-is-over-right-of:before {
  top: 0;
  right: 0;
  bottom: 0;
  width: 4px;
}
.react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-right-of:before {
  width: 4px;
}

.react-page-cell-droppable-is-over-inline-right:before,
.react-page-cell-droppable-is-over-inline-left:before {
  top: 0;
  width: 50%;
  height: 36%;
  max-height: 128px;
  background-color: var(--grey300);
  border: 1px solid var(--minBlack);
}

.react-page-cell-droppable-is-over-inline-left:before {
  left: 0;
}
.react-page-cell-droppable-is-over-inline-right:before {
  right: 0;
}

.react-page-cell-droppable-not-allowed {
  cursor: not-allowed;
}
