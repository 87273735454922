@import 'Button/index.css';

.react-page-controls-mode-toggle-clearfix {
  clear: both;
}

@keyframes fadeIn {
  0% {
   opacity: 0;
   transform: scale(0);
  }
  80% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.react-page-controls-mode-toggle-control {
  z-index: 1;
  animation: fadeIn 0.8s forwards;
  opacity: 0;

}
